import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`“Another Flow Trail?”. Yes yes, we know. But the Flow Trails in St. Moritz
are different! Really built well, they have an awesome rhythm and get more
fun the more you ride and the faster you get.`}</p>
    <br />
    <p>{`The surrounding trails complete
the offer very well and you have incredible and scenic descents on natural
singletracks all around the over 3.000m high Piz Nair. Good you have lift
access up to the very top, so you can use all your power for the long descents.
Best Season is from June to September.`}</p>
    <TrailguidePlugin content="lat=46.5168&lng=9.8251&coverage=10" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=13,46.5168,9.8251"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Piz Nair technical`}</h4>
    <p>{`Top of the world is the slogan of the destination. On the over 3000m high
Piz Nair with the Piz Bernina in the backround, it really feels like this.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2034"
      }}>{`here`}</a>{` to find out more.`}</p>
    <br />
    <Image src="destinations/stmoritz_8.jpg" mdxType="Image" />
    <h4>{`Suvretta Trail`}</h4>
    <p>{`From the Piz Nair, trails go down in every direction.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2036"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/stmoritz_4.jpg" mdxType="Image" />
    <br />
    <p>{`In the upper part, you can surf down the steep gravel slopes.`}</p>
    <br />
    <Image src="destinations/stmoritz_3.jpg" mdxType="Image" />
    <h4>{`Corviglia Flow Trail`}</h4>
    <p>{`There are also really cool flow trails.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2037"
      }}>{`here`}</a>{` to find out more.`}</p>
    <br />
    <Image src="destinations/stmoritz_6.jpg" mdxType="Image" />
    <p>{`Always with a great view.`}</p>
    <br />
    <Image src="destinations/stmoritz_5.jpg" mdxType="Image" />
    <h4>{`Trais Fluors`}</h4>
    <p>{`One of the longest descents is from the Piz Nair down to Schlattain, then
pedal up to Trais Fluors to start the endless descent down to Bevers.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2035"
      }}>{`here`}</a>{` to find out more.`}</p>
    <br />
    <Image src="destinations/stmoritz_1.jpg" mdxType="Image" />
    <p>{`Surfing through flowering meadows.`}</p>
    <Image src="destinations/stmoritz_2.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      